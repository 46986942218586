html, body, #root {
    overflow: hidden;
}

body {
    width: 100dvw;
    height: 100dvh;
}

#root {
    width: 100%;
    height: 100%;
}

a {
    cursor: pointer;
}

.c-app {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

}
