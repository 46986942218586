@import '../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.c-admin-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 3.2rem;
    gap: 1.6rem;

    &__header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
            text-align: center;
            font-size: 4.4rem;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: $spacing-24;
        align-items: center;
        justify-content: center;

        &--authenticated {
            height: 100%;
            overflow: hidden;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        div {
            text-align: center;
            width: 100%;
        }

        &__label {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            gap: 0.4rem;
            span {
                color: $blue;
            }

            .c-login-page__container__error {
                color: $red;
                font-size: $font-size-14;
            }
        }

    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: $spacing-8;
        align-items: center;
        color: $secondary-color;
        text-align: center;
        width: 100%;
    }
}