@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-previous-display {
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(51, 51, 51, 0.25);
  border-radius: .8rem;
  padding: .8rem;
  overflow: hidden;

  h3 {
    font-family: $font-family-licorice;
    font-weight: $font-weight-regular;
    color: $primary-color;
    font-size: $font-size-48; // Standardmäßig für Mobile
    line-height: $heading-line-height;
  }

  &__value {
    font-family: $font-family-amatic;
    font-weight: $font-weight-regular; // Bold Gewicht
    color: $secondary-color;
    font-size: $font-size-32; // Einheitliche Schriftgröße für Mobile und Desktop
    line-height: $heading-line-height;
    text-align: right;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1.6rem;

    div {
      width: auto;
      text-align: left;

      span {
        color: $grey-50;
      }
    }
  }

  &__name {
    text-align: left;
    flex: 1 1;
  }

  &__comment {
    text-align: left !important;
    &--empty {
      color: $grey-60;
    }
  }

  &__date {
    white-space: nowrap;
  }

  &--redeemed,
  &--invalid {
    opacity: .5;
  }

  h3, div {
    position: relative;
    z-index: 2;
  }

  &__invalid-text,
  &__redeemed-text {
    background-color: $primary-color;
    color: $white;
    padding: .8rem;
    margin: -.8rem;
    margin-top: .8rem;
    width: calc(100% + 1.6rem) !important;
  }

  .botch {
    display: block;
    position: absolute;
    width: 256px;
    height: 256px;
    margin-left: -6.4rem;
    margin-top: -6.4rem;
    z-index: 0;
    opacity: .7;
  }

  &:hover {
    box-shadow: 0 0 3px rgba(51, 51, 51, 0.6);
  }
}