@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-information {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__mini-header {
    margin-top: 1.6rem;
    margin-bottom: 0.4rem;
    color: $blue;
  }
}

