@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-list {
  display: flex;
  flex-direction: column;
  gap: .8rem;
  overflow: hidden;
  height: 100%;
  flex: 1 1;

  &__search-bar {
    display: flex;
    flex-direction: row;
    gap: .8rem;
  }

  &__searchfield {
    position: relative;

    .c-button {
      position: absolute;
      top: 1px;
      right: 1px;
      border-style: none;
    }
  }

  &__results {
    flex: 1 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  &__results-container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: .4rem;
  }
  &__results-empty {
    color: $grey-50;
  }

  &__pages {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 600;
    }
  }
}