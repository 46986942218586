@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-detail-view {
  flex: 1 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  overflow: hidden;

  &__container {
    flex: 1 1;
    width: 100%;
    overflow-y: auto;
    position: relative;
    z-index: 0;

    background:
    /* Shadow covers */
    linear-gradient(white 30%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
    
    /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn’t support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }

  &__scrollable-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.2rem;
    padding: 3.2rem 1.6rem;
  }
}