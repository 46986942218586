@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-previous-list {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: .8rem;
  align-items: center;
  width: 100%;
  max-width: 50rem;

  .separator {
    margin-bottom: 1.6rem;
  }

  &__empty {
    color: $grey-60;
  }

  &__voucher-list {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    align-items: center;
    width: 100%;
  }

  &__hint {
    text-align: left;
    margin-top: .8rem;
    strong {
      font-weight: 600;
    }
  }
}