@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-redeem {
  position: fixed;
  width: 100dvw;
  height: calc(100dvh - 7.2rem);
  top: 7.2rem;
  left: 0;
  background-color: $white;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2.4rem;
  padding-top: 0;
  overflow: hidden;
  

  &__mini-header {
    margin-top: 1.6rem;
    margin-bottom: 0.4rem;
    color: $blue;
  }
  
  &__selection {
    padding-top: 2.4rem;
    flex: 1 1;
    overflow-y: scroll;
    width: 100%;
    max-width: 60rem;

    &--loading {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      justify-content: center;
      align-items: center;
    }
  }

  &__service-selection,
  &__service-package-selection,
  &__description-and-value,
  &__new-customer-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  &__service-selection-buttons {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    width: 100%;
    align-items: center;
    justify-content: stretch;

    .c-button {
      width: 100%;
    }
  }

  &__service-package-selection-buttons,
  &__new-customer-selection-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .8rem;
    align-items: center;
    justify-content: center;
  }

  &__description-and-value-textfields {
    display: flex;
    flex-direction: row;
    gap: .8rem;
    width: 100%;

    .textfield__error {
      text-align: left;
    }
  }

  &__description {
    flex: 1 1;
  }

  &__value {
    width: 8rem;
    input {
      text-align: right;
    }
  }

  &__rest {
    width: 100%;
    margin-top:1.6rem;
    text-align: right;
  }

  &__button-bar {
    display: flex;
    width: calc(100% + 4.8rem);
    justify-content: space-between;
    margin: -2.4rem;
    padding: 2.4rem;
    margin-top: 0;
    box-shadow: 0 -4px 4px rgba(51, 51, 51, 0.25);
  }
}

