@import '../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.c-stats-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    width: 100%;
    height: 100%;
    overflow: scroll;

    main {
        display: flex;
        flex-direction: column;
        align-items: left;
        flex: 1 1;
        width: 100%;
        padding: 3.2rem;
        gap: 1.6rem;
    }

    table {
        th {
            font-weight: 600;
        }
        .table {
            &__left {
                text-align: left;
            }

            &__right {
                text-align: right;
            }

            &__data {
                &_total td {
                    padding-bottom: .4rem;
                }
                &_open td {
                    font-weight: 600;
                    padding-top: .4rem;
                }
            }
        }
    }

}