@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-notification {
  width: 100%;
  padding: $spacing-12 $spacing-16;
  text-align: center;
  background: $black;
  color: $white;

  &--invalid {
    background: $red;
  }

  &--redeemed {
    background: $red;
    opacity: .7;
  }
}