@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-history {
  width: 100%;
  max-width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  table {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    border-width: 0;
    border-style: none;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
    }
    th,
    td {
      padding: .4rem .8rem .4rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    th {
      margin-top: 1.6rem;
      margin-bottom: 0.4rem;
      color: $blue;
      border-bottom: 1px solid $grey-95;
      padding-bottom: 0;
    }
  }

  &__entry--hidden {
    opacity: .5;
    td {
      text-decoration: line-through;
    }
  }

  &__date {
    text-align: left;
    width: 10rem;
  }

  &__description {
    text-align: left;
  }

  &__value {
    text-align: right;
    width: 10rem;

    &--positive {
      color: $blue;
    }

    &--negative {
      color: $red;
    }
  }
}

