@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-error-view {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  max-width: 96rem;

  &__help {
    margin-top: 3.2rem;
    padding: $spacing-16;
    background-color: #F8EFF1;
  }

  &__botch {
    position: absolute;
    z-index: -1;
    width: 12.8rem;
    opacity: .5;

    &--top {
      top:0;
      right:0;
    }

    &--bottom {
      bottom:0;
      left:0;
    }

  }
}