.card {
  -moz-perspective: 150rem;
  perspective: 150rem; // use a large number here; is a quite new property
  position: relative;
  width: 100%;
  height: 30rem; // height is needed for the perspective, due to absolute positioning

  &--discount {
    height: 25rem;

    .card__side {
      height: 25rem;
    }
  }

  &__side {
    transition: all .8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30rem;
    backface-visibility: hidden;
    cursor: pointer;

    &--back { // already rotated
      transform: rotateY(180deg);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &--show-back {
   .card__side--front { // rotate on hover the parent element to 180deg
      transform: rotateY(-180deg); 
    }

    .card__side--back { // rotate on hover the parent element back to 0deg
      transform: rotateY(0deg);
    }
  }
}