@import '../../../../node_modules/@benjaminpetry/sisterhoodmassagen-design/lib/design';

.voucher-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  padding-top: 1.6rem;
  gap: 1.6rem;

  &__title {
    font-family: $font-family-licorice;
    font-weight: $font-weight-regular;
    font-size: 10rem;
    color: $red;
    margin-bottom: -3.2rem;
  }

  &__p {
    font-size: 3.2rem;
  }

  div {
    position: relative;
    z-index: 1;
    z-index: 1000;
  }

  &__value {
    z-index: 0 !important;
    position: relative;

    div {
      position: relative;
      font-family: $font-family-amatic;
      font-weight: $font-weight-regular;
      font-size: 6.4rem;
      color: $blue;
      z-index: 1000;
    }

    .botch {
      display: block;
      position: absolute;
      width: 128px;
      height: 128px;
      z-index: 0;
    }

    .voucher-header__value__botch-left {
      left: 0;
      top: 0;
    }
    .voucher-header__value__botch-right {
      right: 0;
      top: 0;
    }
  }

  &--invalid,
  &--redeemed,
  &--deactivated {
    .voucher-header__value {
      opacity: 0.3;
    }
  }

  &.voucher--gift-card {

    .voucher-header__value__botch-left {
      transform: translate(-10rem, -4.8rem)  rotate(82.42deg);
    }
    .voucher-header__value__botch-right {
      transform: translate(8.8rem, -0.1rem)
    }
  }

  &.voucher--discount {
    .voucher-header {
      &__title {
        font-size: 6.4rem;
        margin-bottom: 0rem;
      }
      &__value__botch-left {
        transform: translate(-10rem, -4.8rem) rotate(105.76deg);
      }
      &__value__botch-right {
        transform: translate(8.8rem, -0.1rem) rotate(-8.29deg);
      }
    }
  }
}